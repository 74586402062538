var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"PQpOUnsz43h8WjI7lWKO-"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";
import {
  getSentryDsn,
  isEnabled,
  getEnvironmentClass,
  tracesSampler,
} from "@dbt/commons/utils/sentry";

if (isEnabled()) {
  Sentry.init({
    dsn: getSentryDsn(),
    // Tunneling sentry request through our own endpoint
    // to fix "Ad Blockers" blocking sentry requests.
    tunnel: "/api/sentry/tunnel",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampler: tracesSampler(1.0),
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: getEnvironmentClass(),
  });
}
